<template>
  <page class="h-[100dvh] overflow-hidden">
    <page-content class="h-full">
      <div 
        v-if="isFetched" 
        class="h-full flex items-center justify-center py-4"
        @wheel="handleScroll"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
      >
        <div 
          class="relative w-full overflow-hidden"
          :style="{ 
            maxWidth: isPortrait ? '375px' : '1000px',
            aspectRatio: isPortrait ? '9/16' : '16/9',
            margin: isPortrait ? '0 auto' : undefined,
            height: 'calc(100% - 2rem)'
          }"
        >
          <div 
            class="h-full transition-transform duration-300"
            :style="{ transform: `translateY(-${currentIndex * 100}%)` }"
            @transitionend="onTransitionEnd"
          >
            <div 
              v-for="(video, index) in videoList" 
              :key="video.id"
              class="h-full w-full"
            >
              <shorts-video
                :video="video"
                :active="currentIndex === index"
                @next="nextVideo"
                @previous="previousVideo"
              />
            </div>
          </div>
        </div>
      </div>
    </page-content>
  </page>
</template>

<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query";
import { ref, computed } from "vue";
import ShortsVideo from "./shorts-video.vue";
import VimeoVideosService from "@/services/vimeo-videos-service";
import { useWindowSize } from '@vueuse/core';

const vimeoService = new VimeoVideosService();
const currentIndex = ref(0);

const SCROLL_THRESHOLD = 20;
const SCROLL_COOLDOWN = 500; // ms cooldown between scroll actions
const isScrolling = ref(false);
let lastScrollTime = 0;

const touchStartY = ref(0);

const { data, isFetched } = useQuery({
  queryKey: ["vimeo-videos"],
  queryFn: () => vimeoService.getAll(),
});

const videoList = computed(() => data.value?.videos ?? []);

const nextVideo = () => {
  if (currentIndex.value < videoList.value.length - 1) {
    currentIndex.value++;
  }
};

const previousVideo = () => {
  if (currentIndex.value > 0) {
    currentIndex.value--;
  }
};

function onTransitionEnd() {
  // Reset scroll lock after the slide transition completes
  isScrolling.value = false;
}

function handleScroll(event: WheelEvent) {
  event.preventDefault();
  
  const now = Date.now();
  if (isScrolling.value || (now - lastScrollTime) < SCROLL_COOLDOWN) return;

  if (Math.abs(event.deltaY) > SCROLL_THRESHOLD) {
    isScrolling.value = true;
    lastScrollTime = now;

    if (event.deltaY > 0) {
      nextVideo();
    } else {
      previousVideo();
    }

    setTimeout(() => {
      isScrolling.value = false;
    }, 50);
  }
}

function handleTouchStart(event: TouchEvent) {
  const now = Date.now();
  if ((now - lastScrollTime) < SCROLL_COOLDOWN) return;
  
  touchStartY.value = event.touches[0].clientY;
}

function handleTouchMove(event: TouchEvent) {
  event.preventDefault();
  
  const now = Date.now();
  if (isScrolling.value || (now - lastScrollTime) < SCROLL_COOLDOWN) return;

  const touchEndY = event.touches[0].clientY;
  const deltaY = touchEndY - touchStartY.value;

  if (Math.abs(deltaY) > SCROLL_THRESHOLD) {
    isScrolling.value = true;
    lastScrollTime = now;

    if (deltaY < 0) {
      nextVideo();
    } else {
      previousVideo();
    }

    // Reset scrolling state after a short delay
    setTimeout(() => {
      isScrolling.value = false;
    }, 50);
  }
}

const { width: windowWidth, height: windowHeight } = useWindowSize();

const isPortrait = computed(() => windowHeight.value > windowWidth.value);
</script>

<style scoped>
.h-full {
  overscroll-behavior: contain;
  touch-action: pan-y;
}
</style>
