<template>
  <div class="media-grid flex flex-col h-full">
    <div class="flex justify-end">
      <div class="flex items-center mb-sm">
        <dropdown class="mr-xs" :right="true" :to-right="true">
          <template #dropdownButton>
            <div class="text-label-strong">{{ $t("attributes." + sort) }}</div>
          </template>
          <a v-for="option in sortOptions" :key="option.label" class="h-10" @click="updateSort(option.value)">{{
            option.label
          }}</a>
        </dropdown>
        <a @click="toggleSortDirection">
          <icon-fluent
            class="h-4"
            :name="sortDirection === 'asc' ? 'arrowUp' : 'arrowDown'"
            variant="filled"
          ></icon-fluent>
        </a>
      </div>
    </div>
    <scroll-container class="flex-1 overflow-hidden" @scroll-end="$emit('load-more')">
      <div class="grid grid-cols-2 gap-6 sm:grid-cols-4">
        <media-grid-card
          v-for="mediaFile in mediaFiles"
          :key="mediaFile.id || mediaFile.tempId"
          :media-file="mediaFile"
          :selected="selectedFiles && selectedFiles.includes(mediaFile)"
          @click="$emit('select', mediaFile, $event)"
          @dblclick="$emit('select-one', mediaFile)"
          @delete="$emit('delete', mediaFile)"
        ></media-grid-card>
      </div>
    </scroll-container>
  </div>
</template>

<script lang="ts">
import MaybeUploading from "@/model/maybe-uploading";
import MediaFile from "@/model/media-file";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import MediaGridCard from "@/components/media-library/media-grid-card.vue";
import { MediaLibrarySort, MediaLibrarySortDirection } from "@/components/media-library/media-library.vue";

@Component({
  components: {
    MediaGridCard,
  },
})
export default class MediaGrid extends Vue {
  @Prop()
  mediaFiles: MaybeUploading<MediaFile>[];

  @Prop()
  selectedFiles: MediaFile[];

  @Prop()
  sort: MediaLibrarySort;

  @Prop()
  sortDirection: MediaLibrarySortDirection;

  get sortOptions() {
    return ["name", "type", "date"].map((value) => ({ value, label: this.$t("attributes." + value) as string }));
  }

  updateSort(value) {
    this.$emit("update-sort", value);
  }

  toggleSortDirection() {
    this.$emit("update-sort-direction", this.sortDirection === "asc" ? "desc" : "asc");
  }
}
</script>
