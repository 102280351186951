<template>
  <div class="image-view-container">
    <div class="image-wrapper">
      <span
        class="image-view inline-block align-bottom bg-surface-min-contrast rounded-sm relative"
        :class="{ 'cursor-zoom-in': detailView }"
        @click="openHighResolution"
      >
        <img
          v-lazy="mediumUrl"
          class="image base-image inline rounded-sm"
          :style="imageStyle"
        />
        <img
          v-if="hasHoverImage"
          :src="hoverUrl"
          class="image hover-image inline rounded-sm"
          :class="{ 'is-visible': comparisonMode }"
          :style="imageStyle"
        />
        <transition v-if="detailView" name="fade">
          <div v-if="detailOpen" class="detail-view z-50" @click.stop="closeHighResolution">
            <img :src="currentHighResUrl" @click.stop="closeHighResolution" />
          </div>
        </transition>
      </span>
      <button 
        v-if="hasHoverImage" 
        class="btn-primary btn-sm mt-2"
        @click="toggleComparisonMode"
      >
        {{ comparisonMode ? 'Show Original' : 'Change Image' }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { filePath } from "@/utils/paths";
import ContentBlob from "@/model/content-blob";

@Component({})
export default class ImageView extends Vue {
  @Prop()
  blob: ContentBlob;

  @Prop()
  width: number;

  @Prop({ default: true })
  detailView: boolean;

  @Prop({ default: null })
  hoverBlob: ContentBlob;

  detailOpen = false;
  comparisonMode = false;
  
  get imageStyle() {
    const style: any = {};

    if (this.blob.metadata?.width) {
      style.width = this.blob.metadata.width + "px";
      if (this.blob.metadata.height) {
        style.aspectRatio = this.blob.metadata.width / this.blob.metadata.height;
      }
    }

    if (this.width) {
      style.width = this.width + "px";
    }

    return style;
  }

  get url() {
    return filePath(this.blob);
  }

  get mediumUrl() {
    return filePath(this.blob, "medium");
  }

  get hoverUrl() {
    return this.hoverBlob ? filePath(this.hoverBlob) : null;
  }

  get hasHoverImage() {
    return !!this.hoverBlob;
  }

  get isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
  
  get currentHighResUrl() {
    // Return the high-res URL of the currently visible image
    return this.comparisonMode && this.hoverBlob ? filePath(this.hoverBlob) : filePath(this.blob);
  }
  
  toggleComparisonMode() {
    this.comparisonMode = !this.comparisonMode;
  }
  
  openHighResolution() {
    if (this.detailView) {
      this.detailOpen = true;
    }
  }
  
  closeHighResolution() {
    this.detailOpen = false;
  }
}
</script>

<style lang="scss" scoped>
.image-view-container {
  max-width: calc(100% - 1px);
  display: flex;
  align-items: flex-end;
}

.image-wrapper {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.image-view {
  position: relative;
  display: inline-block;
  
  .hover-image {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.15s ease;
    
    &.is-visible {
      opacity: 1;
    }
  }

  .detail-view {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;

    padding: var(--spacing);
    cursor: zoom-out;

    img {
      max-width: 100%;
      max-height: 100%;
      background-color: var(--ui-color-surface-no-contrast);
    }

    transition: opacity 0.5s ease;
  }
}

.toggle-comparison-btn {
  font-weight: 500;
  letter-spacing: 0.01em;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
</style>
