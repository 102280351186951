<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="flex-1 flex flex-col items-center my-12 text-text-primary text-center">
    <button v-if="back" class="btn-tertiary" @click="prev">
      <icon-fluent class="mr-2" name="arrowLeft" variant="filled" size="16" />
      {{ $t("actions.back") }}
    </button>
    <!-- We have only one step without the payment step -->
    <!-- <dialog-steps v-if="props.step" :step="props.step" :total="props.totalSteps" /> -->
    <slot name="top" />
    <h1 class="text-xl sm:text-3xl my-2 sm:whitespace-pre-wrap">{{ props.title }}</h1>
    <div class="text-label-medium mb-8 sm:whitespace-pre-wrap">
      {{ props.subtitle }}
    </div>
    <div v-if="props.subtitle2" class="text-label-medium mb-8 sm:w-[580px]">
      {{ props.subtitle2 }}
    </div>
    <slot />
    <div class="flex-1" />
    <div class="text-label-medium sm:text-label-large mt-8">
      <div>{{ $t("signup.have_a_question") }}</div>
      <div>support@osgenic.com</div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: 'SignUpView'
});
</script>

<script setup lang="ts">
interface Props {
  step?: number;
  totalSteps?: number;
  title: string | TranslateResult;
  subtitle: string | TranslateResult;
  subtitle2?: string | TranslateResult;
  back?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  back: true,
  totalSteps: 3,
  subtitle2: undefined,
  step: undefined,
});

const emit = defineEmits<{
  (e: 'back'): void
}>();

const prev = () => emit("back");
</script>

<style scoped></style>
