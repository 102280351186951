<template>
  <div class="scroll-container overflow-y-auto h-full" @scroll="onScroll">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class ScrollContainer extends Vue {
  threshold = 10;

  observer: MutationObserver = null;
  mounted() {
    // mutation observer is needed to detect that content end is reached if the content
    // does not overflow (and thus no scrolling will occur)
    this.observer = new MutationObserver(() => {
      this.checkScrollPosition();
    });
    this.observer.observe(this.$el, { childList: true, subtree: true });
  }

  destroyed() {
    this.observer?.disconnect();
  }

  onScroll() {
    this.checkScrollPosition();
  }

  checkScrollPosition() {
    const el = this.$el as HTMLElement;
    const scrollBottom = el.scrollTop + el.offsetHeight;
    if (scrollBottom >= el.scrollHeight - this.threshold) {
      this.$emit("scroll-end");
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-container {
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  scrollbar-width: thin;
  
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
}
</style>
