import Vue from "vue";
import VueRouter from "vue-router";
import { InlineSvgPlugin } from "vue-inline-svg";
import App from "@/app.vue";
import i18n, { setInitialLanguage } from "@/i18n";
import vuexStore, { loadStoreInitialState } from "@/store/store";
import AuthPlugin from "@/auth-plugin";
import Vuelidate from "vuelidate";
import Vue2TouchEvents from "vue2-touch-events";
import Rollbar from "rollbar";
import VueToast from "vue-toast-notification";
import VueLazyload from "vue-lazyload";
import { errorHandler } from "./error-handler";
import FloatingVue from "floating-vue";
import { VueQueryPlugin } from "@tanstack/vue-query";

// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
// import 'vue-toast-notification/dist/theme-sugar.css';

// Import styles
import "floating-vue/dist/style.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@/assets/styles/index.scss";
import "@ui/assets/styles/index.css";
import "flatpickr/dist/flatpickr.css";

// Import components
import "@/components";

// Import directives
import "@/directives";
import { activeTheme, setInitialTheme } from "@/theme";

// Import filters
import "@/filters";
import AnalyticsEventsService from "./services/analytics-events";
import { PiniaVuePlugin, createPinia } from "pinia";

// Import vue plugins
Vue.use(InlineSvgPlugin);
Vue.use(VueRouter);
Vue.use(AuthPlugin);
Vue.use(Vuelidate);
Vue.use(Vue2TouchEvents);
Vue.use(VueToast, { position: "top-right", duration: 5000 });
Vue.use(VueLazyload);
Vue.use(FloatingVue);
Vue.use(i18n);
Vue.use(PiniaVuePlugin);
Vue.use(VueQueryPlugin);

// Bootstrap app
document.addEventListener("DOMContentLoaded", () => {
  if (window.ENV) {
    const environment = window.ENV.ENVIRONMENT;

    window.rollbar = Vue.prototype.$rollbar = new Rollbar({
      accessToken: window.ENV.ROLLBAR_CLIENT_ACCESS_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      enabled: environment !== "development" && environment !== "test",
      payload: {
        environment,
        client: {
          javascript: {
            source_map_enabled: true,
            code_version: window.ENV.GIT_SHA || '1.0.0',
          }
        }
      }
    });

    window.chargebee = window.Chargebee.init({
      site: window.ENV.CHARGEBEE_SITE,
      publishableKey: window.ENV.CHARGEBEE_PUBLISHABLE_KEY,
    });
  }

  Vue.prototype.$trackClick = (target: string) => {
    new AnalyticsEventsService().trackClick(window.location.pathname, target);
  };
  Vue.prototype.$trackPageView = (target: string) => {
    new AnalyticsEventsService().trackPageView(window.location.pathname, target);
  };

  // Set error handler
  Vue.config.errorHandler = errorHandler;

  // Set language and theme from cookie/browser
  setInitialLanguage();
  setInitialTheme();
  loadStoreInitialState();

  const pinia = createPinia();

  const app = new Vue({
    provide: {
      theme: activeTheme,
    },
    render: (h) => h(App),
    store: vuexStore,
    pinia,
  }).$mount();
  document.body.appendChild(app.$el);
});
