<template>
  <div class="relative h-full w-full bg-black">
    <div :id="playerId" class="h-full w-full object-contain"></div>
    <div 
      class="absolute inset-0 z-10"
      @click="togglePlayPause"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, watch, ref } from 'vue';
import Player from "@vimeo/player";

const props = defineProps<{
  video: {
    id: string;
    name: string;
    thumbnail_url: string;
    player_url: string;
  };
  active: boolean;
}>();

defineEmits<{
  // No emits needed
}>();

const playerId = `vimeo-player-${props.video.id}`;
let player: Player;
const isPlaying = ref(false);

const togglePlayPause = async (event: MouseEvent) => {
  event.stopPropagation();
  if (!player) return;

  isPlaying.value = !isPlaying.value;
  try {
    if (isPlaying.value) {
      await player.play();
    } else {
      await player.pause();
    }
  } catch (error) {
    console.error('Vimeo player error:', error);
  }
};

onMounted(() => {
  player = new Player(playerId, {
    url: props.video.player_url,
    responsive: true,
    background: true,
    muted: false,
    controls: false,
    dnt: true,
    title: false,
    byline: false,
    autopause: false,
    loop: true,
    transparent: false,
    keyboard: false,
    playsinline: true
  });
});

watch(() => props.active, (isActive) => {
  if (!player) return;
  
  if (isActive) {
    player.play();
    isPlaying.value = true;
  } else {
    player.pause();
    isPlaying.value = false;
  }
});

onUnmounted(() => {
  player?.destroy();
});
</script>

<style scoped>
.absolute {
  pointer-events: auto;
}
</style>