import ApiService from "@/services/api-service";

interface VimeoVideo {
  uri: string;
  name: string;
  pictures: {
    sizes: Array<{
      width: number;
      height: number;
      link: string;
    }>;
  };
}

export default class VimeoVideosService extends ApiService<VimeoVideo> {
  get baseUrl() {
    return "/api/v1/vimeo_videos";
  }
}
